import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: 'error-log',
    method: 'get',
    params: query
  })
}

