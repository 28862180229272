<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trades</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          
        </div>
      </el-col>
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
        <div class="btn-wrapper" v-if="getRoleSlug != 'superadmin'">
          <!-- <el-button type="primary" :loading="isExcelReady" @click="handleExportExcel()">Export to Excel</el-button> -->
          <!-- <el-button type="primary" @click="handleSearch()">Search</el-button>
          <el-button type="primary" @click="handleReset()" >Reset</el-button> -->
        </div>
      </el-col>


        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="trading-client">
            <el-form
              :model="formData"
              ref="refForm"
              label-position="top"
              :status-icon="true"
            >
              <el-row :gutter="10">
                <!-- <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.orderId"
                      label="Id"
                      prop="orderId"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.orderId" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.script"
                      label="Script"
                      prop="script"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.script" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.segment"
                      label="Segment"
                      prop="segment"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.segment" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col> -->
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.userId"
                      label="User Id"
                      prop="userId"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.userId" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.userId"
                      label="Daterange"
                      prop="Date Range"
                      :label-width="formLabelWidth"
                    >
                    <el-date-picker
                      v-model="listQuery.date_range"
                      type="daterange"
                      range-separator="To"
                      start-placeholder="Start date"
                      value-format ="yyyy-MM-dd"
                      end-placeholder="End date">
                    </el-date-picker>
                    </el-form-item>
                  </div>
                </el-col>

                <!-- <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.status"
                      label="Status"
                      prop="status"
                      :label-width="formLabelWidth"
                    >
                    <el-select v-model="listQuery.status" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Actve" value="active" ></el-option>
                        <el-option label="Close" value="close" ></el-option>
                    </el-select>
                    </el-form-item>
                  </div>
                </el-col>

                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.close_time"
                    label="Trade time"
                    prop="close_time"
                    :label-width="formLabelWidth"
                  >
                     <el-select v-model="listQuery.close_time" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="2 min" value="2" ></el-option>
                        <el-option label="5 min" value="5" ></el-option>
                        <el-option label="10 min" value="10" ></el-option>
                      </el-select>
                  </el-form-item>
                </div>
              </el-col> -->


                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                  <div class="search-wrapper">
                  <el-form-item label="" style="line-height: 40px;">
                    &nbsp;
                    </el-form-item>
                    <div class="broker-search-wrapper">
                    <el-button type="primary" @click="handleSearch()"
                      >Search</el-button
                    >
                    <el-button type="primary" @click="handleReset()"
                      >Reset</el-button
                    >
                    </div>
                  
                </div>
                  
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>

    </el-row>
    </div>
    <Table
      tableKey="Error Log"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableActionViewVisibility="false"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
      :showSummary="false"
       @edit="handleEdit($event)"
       @delete="handleDelete($event)"
       @view="handleViewDetails($event)"
      @pagination="handlePagination()"
    />
    <!-- <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :listAdmins="listAdmins"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    /> -->
  </div>
</template>


<script>
import Table from "@/components/Table/Table1.vue";
import { fetchList, edit, update, destroy as deleteTrade, tradeExport } from "@/api/error";
// import { _ } from "vue-underscore";
import getRoleSlug from "@/store/app.js";
import { store } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";



export default {
  name: "ListTrades",
  components: {
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      tableActionViewVisibility:false,
      tableActionDeleteVisibility : false,
      dialogType: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      tablePagination: {},
      formData: {},
      formErrors: [],
      formLabelWidth: "120px",
      isExcelReady: false,
      config: [
        {
          label: "Id",
          prop: "user_id",
          width: "120",
          className: "blueFont",
          popover: false,
        },
        {
          label: "User Name",
          prop: "user_name",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Error",
          prop: "error",
          width: "530",
          className: "blueFont",
        },
        {
          label: "Time",
          prop: "time",
          width: "180",
          className: "blueFont",
        },
      ],
      listData: {
        data: {
          item: [],
        },
      },
      listAdmins: [],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        date_from:"",
        date_to:""
      },
      data: {},
      // data: {
      //   data: {
      //     item: [
      //       {
      //         id: "001",
      //         script: "CRUDEOIL_17DEC2021",
      //         segment: "MCX",
      //         user_id: "vijay1234 : vijay",
      //         buy_rate: "5667",
      //         sell_rate: "5680",
      //         lots_units: "1 lots",
      //         bought_at: "2021-11-22 20:22:15",
      //         sold_at: "2021-11-22 20:37:54",
      //       },
      //     ],
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getList();
  },
  methods: {
    handleExportExcel() {
      this.isExcelReady = true
      tradeExport(this.listQuery).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
        this.isExcelReady = false
      })
      .catch((err) => {
        console.log(err);
      });
    },
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
    handleReset() {
      this.listQuery = {}
      this.listQuery.page = 1;
      this.getList();
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
     
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      if(this.listQuery.date_range){
        this.listQuery.date_from = this.listQuery.date_range[0]
        this.listQuery.date_to = this.listQuery.date_range[1]
      }
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    getList(showLoading) {
     if(showLoading !== false){      
        this.loading = true;
      }
      fetchList(this.listQuery).then((response) => {
        // this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;

        if(showLoading !== false){      
          this.loading = false;
        }
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleViewDetails(data) {
       console.log(data)
       if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push("/super-admin/trades/trades-view/" +data.id );
      } else {
        return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trades/trades-view/" + data.id);
      }
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {
        segment: "",
      };
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
        store(data)
          .then((response) => {
            if (response.data.success === true) {
              this.dialogVisible = false;
              this.flashSuccess(response.data.message);
              this.getList();
              this.loading = false;
            } else {
              this.flashError(response.data.message);
              this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // //////////////////
    // EDIT
    // //////////////////
    handleEdit(id) {
      this.fetchData(id);
    },
    fetchData(id) {
      this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.loading = false;
            this.dialogType = "edit";
            this.dialogVisible = true;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleUpdate(data) {
      this.loading = true;
      update(data.id, data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.loading = false;
            this.flashSuccess(response.data.message);
            //this.getList();

            if(getRoleSlug.state.roleSlug == 'superadmin') {
              return this.$router.push("/super-admin/trades/trades-view/" +data.id );
            } else {
              return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trades/trades-view/" + data.id);
            }
          } else {
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        deleteTrade(data.id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },
  },
};
</script>
